/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '@angular/cdk/overlay-prebuilt.css';

.copy-button {
  position: absolute !important;
  right: 0;
  top: -32px;
}

body {
  background-color: #EEE;
  margin: 0;
}

.spacer {
  flex: 1 1 auto;
}

.mat-mdc-card {
  min-width: 1100px;
  margin: 15px;
  padding: 15px;
}

.mat-mdc-row {
  cursor: pointer;
}

.mat-mdc-row:hover {
  background-color: #F0F0F0;
}

.mat-sort-header-sorted {
  color: black;
}

.mdc-text-field {
  background-color: white !important;
}

.mdc-form-field {
  padding: 5px;
}

.links {
  border-left: solid 2px white;
  margin-left: 15px;
  padding-left: 10px;
}

mat-toolbar {
  a {
    color: white;
    text-decoration: underline;
    margin: 5px;
    font-size: 15px;
  }

  a.active {
    color: gray;
    text-decoration: none;
  }
}

.dark-backdrop {
  background: #000 !important;
  opacity: 0.75 !important;
}
